import React, { useEffect, useMemo } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import {
  Flex,
  Text,
  CircularProgress,
  Box,
  Center,
  CircularProgressLabel,
  Icon,
  Image,
  useDisclosure,
  Divider,
  FlexProps,
} from '@chakra-ui/react';
import { useLazyGetAvailabilityCardQuery } from '@spartalabs/pdc-core';
import { Reserved } from 'assets';
import { BiSkeleton } from 'components/Skeletons/Bi';
import { ForkLift } from '../CustomIcons/ForkLift';
import { CardModal } from '../Modal/BiModal';
import { Card } from './Card';
import { ErrorCard } from './ErrorCard';

interface BiCardsProps extends FlexProps {
  isSolo?: boolean;
  costCenters: string;
  cardOptions?: CardOptions;
  cardRef?: CardRef;
}

export const BiCards: React.FC<BiCardsProps> = ({
  isSolo,
  costCenters = '',
  cardOptions,
  cardRef,
  ...rest
}) => {
  const modal = useDisclosure();

  const [fetch, { data, isLoading, isFetching, isError }] =
    useLazyGetAvailabilityCardQuery();

  const cardsInfo = useMemo(
    () => [
      {
        text: 'Disponibilidade da frota',
        width: {
          base: '100%',
          md: '48%',
          lg: '32%',
          xl: '200px',
        },
        value: data?.fleetAvailability || 0,
      },
      {
        text: 'Frota ativa',
        width: {
          base: '46%',
          md: '48%',
          lg: '32%',
          xl: '200px',
        },
        color: '#FFBB38',
        bg: '#FFF5D9',
        value: data?.activeFleet || 0,
      },
      {
        text: 'Frota disponível',
        width: {
          base: '46%',
          md: '48%',
          lg: '32%',
          xl: '200px',
        },
        color: '#4AD991',
        bg: '#D9F7E7',
        icon: IoMdCheckmark,
        value: data?.availableFleet || 0,
      },
      {
        text: 'Frota efetiva',
        width: {
          base: '100%',
          md: '48%',
          lg: '32%',
          xl: '200px',
        },
        color: '#396AFF',
        bg: '#E7EDFF',
        value: data?.effectiveFleet || 0,
      },
      ...(isSolo
        ? [
            {
              width: {
                base: '100%',

                xl: '440px',
              },
              isMultiple: true,
              isPercent: false,
              color: '#396AFF',
              bg: '#E7EDFF',
            },
          ]
        : []),
      {
        text: 'Frota reserva',
        width: {
          base: '46%',

          lg: '32%',
          xl: '200px',
        },
        color: '#FF9871',
        bg: '#FFDED2',
        hasImage: true,
        value: data?.reserveFleet || 0,
      },

      {
        text: 'Reserva em uso',
        width: {
          base: '46%',

          lg: '32%',
          xl: '200px',
        },
        color: '#FF82AC',
        bg: '#FFE0EB',
        hasImage: true,
        value: data?.reserveInUse || 0,
      },
    ],
    [data],
  );

  useEffect(() => {
    if (costCenters) fetch(costCenters);
  }, [costCenters]);

  return (
    <Flex
      ref={cardRef}
      _hover={
        cardOptions?.widget.size === '50%'
          ? {
              borderColor: 'ocean.400',
            }
          : undefined
      }
      flexWrap="wrap"
      flex={1}
      w="100%"
      gap="20px"
      {...cardOptions?.dragHandler?.dragHandleProps}
      {...rest}
    >
      <CardModal isOpen={modal.isOpen} onClose={modal.onClose} />
      {isError ? (
        <ErrorCard refetch={() => fetch(costCenters)} minH="104px" w="1000%" />
      ) : (
        cardsInfo.map(card =>
          isFetching || isLoading || costCenters?.length < 1 ? (
            <BiSkeleton key={card.text} />
          ) : (
            <Card
              maxW={{ xl: isSolo ? '244px' : '100%' }}
              flex={1}
              key={card.text}
              minH="104px"
              h="104px"
              w={card.width}
              minW={card.width}
            >
              {card.color && !card.isMultiple ? (
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Text maxW="120px" fontSize="16px" textColor="text.500">
                      {card.text}
                    </Text>
                    {card.value || card.text !== 'Frota reserva' ? (
                      <Text
                        fontSize="32px"
                        textColor="text.100"
                        fontWeight={600}
                      >
                        {card.value}
                      </Text>
                    ) : (
                      <Text
                        cursor="pointer"
                        onClick={modal.onOpen}
                        decoration="underline"
                        mt="16px"
                        fontSize="14px"
                        textColor="blue.0"
                        fontWeight={600}
                      >
                        Saiba mais
                      </Text>
                    )}
                  </Box>
                  <Center
                    position="relative"
                    borderRadius="8px"
                    bg={card.bg}
                    w="56px"
                    h="56px"
                  >
                    {card.icon && (
                      <Icon
                        p="4px"
                        w="20px"
                        h="20px"
                        bg={card.color}
                        color="white"
                        borderRadius={20}
                        top="-8px"
                        right="-8px"
                        position="absolute"
                        as={card.icon}
                      />
                    )}
                    {card.hasImage && (
                      <Image
                        w="24px"
                        h="24px"
                        color="white"
                        top="-10px"
                        right="-8px"
                        position="absolute"
                        src={Reserved}
                      />
                    )}

                    <ForkLift color={card.color} />
                  </Center>
                </Flex>
              ) : card.isMultiple && isSolo ? (
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box w="100%">
                    <Text minW="156px" fontSize="16px" textColor="text.500">
                      Total em manutenção
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.totalInMaintenance}
                    </Text>
                  </Box>
                  <Divider
                    mt="20px"
                    orientation="vertical"
                    borderColor="#D3D3D4"
                    h="25px"
                    borderWidth="1px"
                  />
                  <Box pl="8px" w="40%">
                    <Text fontSize="16px" textColor="text.500">
                      Parado
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.stopped}
                    </Text>
                  </Box>
                  <Divider
                    mt="20px"
                    orientation="vertical"
                    borderColor="#D3D3D4"
                    h="25px"
                    borderWidth="1px"
                  />
                  <Box pl="8px" w="60%">
                    <Text fontSize="16px" textColor="text.500">
                      Rodando
                    </Text>

                    <Text fontSize="32px" textColor="text.100" fontWeight={600}>
                      {data?.running}
                    </Text>
                  </Box>
                  {card.color && (
                    <Center
                      display={{ base: 'none', sm: 'grid' }}
                      position="relative"
                      borderRadius="8px"
                      bg={card.bg}
                      w="56px"
                      h="56px"
                    >
                      <ForkLift color={card.color} />
                    </Center>
                  )}
                </Flex>
              ) : (
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text w="140px" fontSize="16px" textColor="text.100">
                    {card.text}
                  </Text>

                  <CircularProgress
                    color="green.100"
                    thickness="4px"
                    size="72px"
                    value={Number(data?.fleetAvailability) || 0}
                  >
                    <CircularProgressLabel textColor="text.100">
                      {data?.fleetAvailability || 0}%
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              )}
            </Card>
          ),
        )
      )}
    </Flex>
  );
};
