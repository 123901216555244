import { BiTrafficCone } from 'react-icons/bi';
import { BsShieldCheck } from 'react-icons/bs';
import { IoIosSettings, IoMdGlobe } from 'react-icons/io';
import { MdOutlineBuild } from 'react-icons/md';
import { colors } from 'theme/components/colors';

export const SIZE_CHANGE = 847;

export const DETAILS_LABEL = 'Ver Detalhes';

//#region <GA>
export const GA4_KEY = 'G-405WD6LC7R';
export const GA_CUSTOMIZE_WIDGETS_LABEL = 'Personalização de Widgets';
export const CUSTOMIZE_COLOR_LABEL = 'Personalização de Cor';
export const CUSTOMIZE_LABEL = 'Personalização de Marca';
export const GA_ONBOARDING = 'Onboarding';
export const GA_GUIDED_TOUR = 'Tour Guiado';
export const GA_DOWNLOAD_LABEL = 'Baixar planilha';
export const GA_NEW_SCHEDULE_LABEL = 'Novo Agendamento';
export const GA_FINANCIAL_LABEL = 'Faturamento';
export const GA_FLEETDIST_LABEL = 'Distribuição da frota';
export const GA_FAQ_LABEL = 'Central de ajuda';
export const GA_TABLE_LABEL = 'Tabelas';
export const GA_SIDEBAR = 'Menu Lateral';
export const GA_VEHICLE = 'Veículos';
export const GA_Fines = 'Multas';
export const GA_SIDEBAR_AFTER_LOGIN =
  'Primeiro botão do menu lateral após login';
export const GA_LOGIN = 'Login';
//#endregion

export const services = [
  { id: '0', name: 'PNEU' },
  { id: '1', name: 'MANUTENÇÃO CORRETIVA' },
  { id: '2', name: 'REVISÃO PREVENTIVA' },
  { id: '3', name: 'PREVENTIVA DE IMPLEMENTO' },
  { id: '4', name: 'TACÓGRAFO' },
  { id: '5', name: '2º VIA DE PLACA' },
  { id: '6', name: 'SINISTRO' },
  { id: '7', name: 'DOCUMENTAÇÃO' },
  { id: '8', name: 'TESTE ACÚSTICO' },
  { id: '9', name: 'TESTE ELÉTRICO' },
  { id: '10', name: 'TESTE DE OPACIDADE' },
  { id: '11', name: 'GARANTIA' },
  { id: '12', name: 'PÓS IMPLANTAÇÃO' },
  { id: '13', name: 'REVISÃO DE ASSENTAMENTO' },
  { id: '14', name: 'FURTO/ROUBO VEÍCULO' },
  { id: '15', name: 'FURTO/ROUBO ACESSÓRIO' },
];
type PageInfo = {
  title: string;
  route: string;
};

export const redirectEnum: { [key: number]: PageInfo } = {
  1: { title: 'Agendamento', route: '/agendamentos' },
  2: { title: 'Faturamento', route: '/faturamento' },
  3: { title: 'Central de Dúvidas', route: '/centraldeduvidas' },
  4: { title: 'Gestão de Contratos', route: '/gestaodecontratos' },
  5: { title: 'Infrações/Multas', route: '/gestao/multas' },
  6: { title: 'Relatórios - Modelos', route: '/relatorios/modelos' },
  7: { title: 'Arquivo', route: '' },
  8: {
    title: 'Relatórios - Entrada e Saída',
    route: '/relatorios/manutencao/entradasaida',
  },
  9: {
    title: 'Relatórios - Serviços',
    route: '/relatorios/manutencao/servicos',
  },
  10: { title: 'Apenas comunicado', route: '' },
  11: {
    title: 'Relatórios - Distribuição de Frota',
    route: '/relatorios/distdefrotas',
  },
  12: { title: 'Link', route: '' },
};

//#region <Calendar>
export const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

interface MonthToNumProps {
  [month: string]: string;
}

export const monthsToNum: MonthToNumProps = {
  Jan: '01',
  Fev: '02',
  Mar: '03',
  Abr: '04',
  Mai: '05',
  Jun: '06',
  Jul: '07',
  Ago: '08',
  Set: '09',
  Out: '10',
  Nov: '11',
  Dez: '12',
};
//#endregion

export const ChartTypeFilters: ChartTypeFilter[] = [
  {
    label: 'Serviços Total',
    icon: IoMdGlobe,
    type: 'total',
  },
  {
    label: 'Serviços Preventivos',
    icon: BsShieldCheck,
    type: 'prev',
  },
  {
    label: 'Serviços Corretivos',
    icon: MdOutlineBuild,
    type: 'corr',
  },
  {
    label: 'Serviços Sinistro',
    icon: BiTrafficCone,
    type: 'sin',
  },
  {
    label: 'Serviços Outros',
    icon: IoIosSettings,
    type: 'others',
  },
];

export const defaultColorArray = [
  colors.yellow['100'],
  colors.orange['100'],
  colors.blue['100'],
  colors.grey['600'],
  colors.green['100'],
];

export const emptyData: ChartData[] = [
  {
    label: '',
    values: [0],
  },
];

//#region <Tables>
export const ServiceTable: TableLabel[] = [
  {
    title: 'Nº de atendimento',
    type: 'data',
    accessor: 'id',
  },
  {
    title: 'Placas',
    type: 'data',
    accessor: 'plate',
  },
  {
    title: 'Status do atendimento',
    type: 'text',
    accessor: 'status',
  },
  {
    title: 'Motivo',
    type: 'text',
    accessor: 'type',
  },
  {
    title: 'Data de Agendamento',
    type: 'text',
    accessor: 'scheduleDate',
  },
  {
    title: 'Data de Previsão',
    type: 'text',
    accessor: 'previsionDate',
  },
  {
    title: 'Data de Saída',
    type: 'text',
    accessor: 'exitDate',
  },
  {
    title: 'Situação do Veículo',
    type: 'text',
    accessor: 'situation',
  },
  {
    title: 'Mais Detalhes',
    type: 'text',
    accessor: 'details',
  },
];

export const ServiceFilters = [
  {
    label: 'Nº de atendimento',
    type: 'text',
    placeholder: 'Todas',
    accessor: 'id',
  },
  {
    label: 'Placas',
    type: 'plate',
    placeholder: 'AAA0A00',
    accessor: 'plate',
  },
  {
    label: 'Status do Atendimento',
    type: 'text',
    placeholder: 'Status',
    accessor: 'scheduleStatus',
  },
  {
    label: 'Motivo',
    type: 'text',
    placeholder: 'Motivo',
    accessor: 'reason',
  },
  {
    label: 'Data de Agendamento',
    type: 'date',
    placeholder: 'Data',
    accessor: 'scheduleDay',
  },
  {
    label: 'Data de Previsão',
    type: 'date',
    placeholder: 'Data',
    accessor: 'previsionDate',
  },
  {
    label: 'Data de Saída',
    type: 'date',
    placeholder: 'Data',
    accessor: 'exitDate',
  },
  {
    label: 'Situação do Veículo',
    type: 'text',
    placeholder: 'Situação',
    accessor: 'status',
  },
];

export const totalTableLabels: TableLabel[] = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Cor',
    type: 'text',
    accessor: 'color',
  },
  {
    title: 'Região',
    type: 'text',
    accessor: 'region',
  },
  {
    title: 'Cidade',
    type: 'text',
    accessor: 'city',
  },
  {
    title: 'Estado',
    type: 'text',
    accessor: 'uf',
  },
];

export const ageTableLabels: TableLabel[] = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Cor',
    type: 'text',
    accessor: 'color',
  },
  {
    title: 'Região',
    type: 'text',
    accessor: 'region',
  },
  {
    title: 'Cidade',
    type: 'text',
    accessor: 'city',
  },
  {
    title: 'Estado',
    type: 'text',
    accessor: 'uf',
  },
  {
    title: 'Idade',
    type: 'text',
    accessor: 'age',
  },
];

export const kmTableLabels: TableLabel[] = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Cor',
    type: 'text',
    accessor: 'color',
  },
  {
    title: 'Região',
    type: 'text',
    accessor: 'region',
  },
  {
    title: 'Cidade',
    type: 'text',
    accessor: 'city',
  },
  {
    title: 'Estado',
    type: 'text',
    accessor: 'uf',
  },
  {
    title: 'Km',
    type: 'text',
    accessor: 'km',
  },
];

export const averageKmTableLabels: TableLabel[] = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Data de Entrada',
    type: 'text',
    accessor: 'deliveryDate',
  },
  {
    title: 'Prazo Contratual',
    type: 'text',
    accessor: 'contractDeadline',
  },
  {
    title: 'Km Ult Passagem',
    type: 'text',
    accessor: 'lastRevisionKm',
  },
  {
    title: 'Data Ult Passagem',
    type: 'text',
    accessor: 'lastTicketDate',
  },
  {
    title: 'Km Médio',
    type: 'text',
    accessor: 'averageKm',
  },
];

export const entranceExitLabels: TableLabel[] = [
  {
    title: 'Nº de atendimento',
    type: 'data',
    accessor: 'id',
  },
  {
    title: 'Placas',
    type: 'data',
    accessor: 'plate',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Data de Entrada',
    type: 'text',
    accessor: 'entranceData',
  },
  {
    title: 'Data de Saída',
    type: 'text',
    accessor: 'exitDate',
  },
  {
    title: 'Carro reserva',
    type: 'text',
    accessor: 'backupPlate',
  },
];

export const ModelTable: TableLabel[] = [
  {
    title: 'Placas',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Ano Modelo',
    type: 'year',
    accessor: 'yearModel',
  },
  {
    title: 'Fabricação',
    type: 'text',
    accessor: 'yearFabrication',
  },
  {
    title: 'Cor',
    type: 'text',
    accessor: 'color',
  },
  {
    title: 'Região',
    type: 'text',
    accessor: 'region',
  },
  {
    title: 'Km Atual',
    type: 'text',
    accessor: 'km',
  },
];

export const accessLabels: TableLabel[] = [
  {
    title: 'Nome',
    type: 'text',
    accessor: 'name',
  },
  {
    title: 'CPF',
    type: 'text',
    accessor: 'cpf',
    disableSorting: true,
  },
  {
    title: 'E-mail',
    type: 'text',
    accessor: 'email',
  },
  {
    title: 'Telefone',
    type: 'number',
    accessor: 'phone',
  },
  {
    title: 'Grupo',
    type: 'text',
    accessor: 'group',
    disableSorting: true,
  },
  {
    title: 'Perfil',
    type: 'text',
    accessor: 'role',
    disableSorting: true,
  },
];
export const AvailabilityTable: TableLabel[] = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'licensePlate',
  },
  {
    title: 'Chassi',
    type: 'text',
    accessor: 'chassi',
  },
  {
    title: 'Tipo',
    type: 'text',
    accessor: 'type',
  },
  {
    title: 'Filial',
    type: 'text',
    accessor: 'branch',
  },
  {
    title: 'Nome da OS',
    type: 'text',
    accessor: 'reasonForOs',
  },
  {
    title: 'Dias em manutenção',
    type: 'year',
    accessor: 'daysInMaintenance',
  },
  {
    title: 'Veículo reserva',
    type: 'year',
    accessor: 'reserve',
  },
  {
    title: 'Placa reserva',
    type: 'year',
    accessor: 'reservePlate',
  },
];

export const PreventiveTable = [
  {
    title: 'Placa',
    type: 'text',
    accessor: 'licensePlate',
  },
  {
    title: 'Chassi',
    type: 'text',
    accessor: 'chassis',
  },
  {
    title: 'KM atual',
    type: 'text',
    accessor: 'currentKm',
  },
  {
    title: 'Última revisão',
    type: 'year',
    accessor: 'lastServiceDate',
  },
  {
    title: '(KM) Última revisão',
    type: 'text',
    accessor: 'lastServiceKm',
  },
  {
    title: 'Intervalo KM',
    type: 'text',
    accessor: 'intervalKm',
  },
  {
    title: 'Intervalo tempo',
    type: 'text',
    accessor: 'intervalDays',
  },
  {
    title: 'Status',
    type: 'tag',
    accessor: 'preventiveStatus',
  },
];

//#endregion

export const mockedWidgets = [
  {
    id: '0',
    name: 'Regiões do brasil - distribuição da frota pelo país',
    size: '100%',
  },
  { id: '1', name: 'Total de atendimentos realizados', size: '100%' },
  { id: '2', name: 'Serviços realizados', size: '100%' },
  { id: '3', name: 'Entrada e saída de manutenções', size: '100%' },
  {
    id: '4',
    name: 'Distribuição de veículos por idade e km',
    size: '100%',
  },
  {
    id: '5',
    name: 'Distribuição de frotas por modelos',
    size: '100%',
  },
];

//#region <Skeleton>
export const skeletonLabels = ['', '', '', '', '', '', '', '', '', '', '', ''];

export const skeletonLineData: ChartData[] = [
  {
    label: '',
    borderColor: colors.grey['500'],
    values: skeletonLabels.map(() => Math.floor(Math.random() * 100)),
  },
];

export const skeletonBarData: ChartData[] = [
  {
    label: '',
    fill: {
      type: 'start',
      color: colors.grey['500'],
    },
    values: skeletonLabels.map(() => Math.floor(Math.random() * 100)),
  },
];

export const ServiceTypes = [
  {
    id: '3',
    name: 'CORRETIVA',
  },
  {
    id: '5',
    name: 'DESMOBILIZAÇÃO',
  },
  {
    id: '6',
    name: 'DOCUMENTAÇÃO',
  },
  {
    id: '9',
    name: 'PREPARAÇÃO PARA VENDA',
  },
  {
    id: '10',
    name: 'PREVENTIVA',
  },
  {
    id: '12',
    name: 'SINISTRO',
  },
  {
    id: '13',
    name: 'GUINCHO',
  },
  {
    id: '20',
    name: 'IMPLANTAÇÃO',
  },
  {
    id: '22',
    name: 'PNEU',
  },
  {
    id: '28',
    name: 'PREPARAÇÃO PARA LOCAÇÃO',
  },
  {
    id: '42',
    name: '1° TACÓGRAFO',
  },
  {
    id: '47',
    name: 'AFERIÇÃO TACÓGRAFO',
  },
  {
    id: '1',
    name: 'ANTECIPAÇÃO DE FROTA',
  },
  {
    id: '2',
    name: 'APREENSÃO',
  },
  {
    id: '4',
    name: 'CORRETIVA PARA VENDA',
  },
  {
    id: '7',
    name: 'FURTO/ROUBO ACESSÓRIO',
  },
  {
    id: '8',
    name: 'FURTO/ROUBO VEÍCULO',
  },
  {
    id: '55',
    name: 'PREDITIVA',
  },
  {
    id: '11',
    name: 'RECUPERADO ROUBO',
  },
  {
    id: '14',
    name: 'ENTREGA TÉCNICA',
  },
  {
    id: '15',
    name: 'TRANSPORTE OPERACIONAL',
  },
  {
    id: '19',
    name: 'OFICINA MÓVEL',
  },
  {
    id: '26',
    name: 'REVISÃO DE IMPLEMENTO',
  },
  {
    id: '27',
    name: 'REVISÃO DE ASSENTAMENTO',
  },
  {
    id: '30',
    name: 'PRE APROVADO',
  },
  {
    id: '31',
    name: 'TAXA ADMINISTRATIVA',
  },
  {
    id: '32',
    name: 'IMPLEMENTO CORRETIVA',
  },
  {
    id: '33',
    name: 'IMPLEMENTO PREVENTIVA',
  },
  {
    id: '34',
    name: 'IMPLEMENTO SINISTRO',
  },
  {
    id: '37',
    name: 'GARANTIA',
  },
  {
    id: '38',
    name: 'REVISÃO ANUAL',
  },
  {
    id: '39',
    name: 'LAVAGEM',
  },
  {
    id: '40',
    name: 'ABASTECIMENTO',
  },
  {
    id: '41',
    name: 'REVITALIZAÇÃO',
  },
  {
    id: '43',
    name: 'TESTE ELÉTRICO',
  },
  {
    id: '44',
    name: 'ANTT',
  },
  {
    id: '45',
    name: 'AET',
  },
  {
    id: '46',
    name: 'SINISTRO C/ TERCEIRO',
  },
  {
    id: '48',
    name: 'RESSARCIMENTO FORNECEDOR',
  },
  {
    id: '49',
    name: 'REVISÃO INTERMEDIÁRIA',
  },
  {
    id: '50',
    name: 'TESTE ACÚSTICO',
  },
  {
    id: '51',
    name: 'TESTE OPACIDADE',
  },
  {
    id: '52',
    name: 'VISTORIA',
  },
  {
    id: '53',
    name: 'OUTRAS INSPEÇÕES',
  },
  {
    id: '54',
    name: 'RECUPERAÇÃO',
  },
  {
    id: '56',
    name: 'FRETE FRACIONADO',
  },
  {
    id: '57',
    name: 'REPOSIÇÃO DE ESTOQUE',
  },
];
//#endregion

export const logFilterOptions = [
  {
    title: 'Seção acessada',
    options: ['Faturamento', 'Agendamento', 'Central de Ajuda', 'CRLV'],
  },
  {
    title: 'Ação',
    options: [
      'Criou conteúdo',
      'Alterou conteúdo',
      'Deletou conteúdo',
      'Baixou conteúdo',
      'Cancelamento',
      'Aprovação Fatura',
      'Contestação Fatura',
      'CRLV não Encontrado',
      'CRLV em Lote',
    ],
  },
  {
    title: 'Status',
    options: ['Sucesso', 'Erro'],
  },
];
