import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Center } from '@chakra-ui/react';
import {
  setCostCenters,
  useAppDispatch,
  useAppSelector,
  useLazyGetCostCenterQuery,
} from '@spartalabs/pdc-core';
import { Loading } from 'components';
import usePageTracking from 'hooks/usePageTracking';
import { isProd } from 'utils/functions';
import Login from '../pages/Login';

//const CallCenter = lazy(() => import('../pages/CallCenter'));

const Dashboard = lazy(() => import('../pages/Dashboard'));
const AccessUser = lazy(() => import('../pages/Access/User'));
const AccessList = lazy(() => import('../pages/Access/List'));
const Contract = lazy(() => import('../pages/Contracts/Contract'));
const ContractsList = lazy(() => import('../pages/Contracts/ContractsList'));
const Empty = lazy(() => import('../pages/Empty'));
const FaQ = lazy(() => import('../pages/FaQ/Categories'));
const EditCategorie = lazy(() => import('../pages/FaQ/EditCategorie'));
const FormQuestion = lazy(() => import('../pages/FaQ/FormQuestion'));
const FaqQuestion = lazy(() => import('../pages/FaQ/Question'));
const QuestionList = lazy(() => import('../pages/FaQ/QuestionList'));
const FinancialList = lazy(() => import('../pages/Financial/List'));
const Logs = lazy(() => import('../pages/Logs'));
const LogsPdv = lazy(() => import('../pages/LogsPdv'));
const Fines = lazy(() => import('../pages/Management/Fines/FinesList'));
const Vehicles = lazy(() => import('../pages/Management/Vehicles'));
const Recall = lazy(() => import('../pages/Management/Recall'));
const FleetDist = lazy(() => import('../pages/Reports/FleetDist'));
const EntranceExit = lazy(
  () => import('../pages/Reports/Maintenance/EntranceExit'),
);
const Repair = lazy(() => import('../pages/Reports/Maintenance/Repair'));
const Preventives = lazy(
  () => import('../pages/Reports/Maintenance/Preventive'),
);

const Availability = lazy(
  () => import('../pages/Reports/Maintenance/Availability'),
);

const Models = lazy(() => import('../pages/Reports/Models'));
const Schedules = lazy(() => import('../pages/Schedules'));
const MultipleForm = lazy(
  () => import('../pages/Schedules/components/MultipleForm'),
);
const ScheduleForm = lazy(
  () => import('../pages/Schedules/components/ScheduleForm'),
);
const ServicesForm = lazy(
  () => import('../pages/Schedules/components/ServicesForm'),
);
const TableView = lazy(() => import('../pages/Schedules/components/TableView'));
const VehicleForm = lazy(
  () => import('../pages/Schedules/components/VehicleForm'),
);
const ImagesForm = lazy(
  () => import('../pages/Schedules/components/ImagesForm'),
);

const FineVehicleForm = lazy(
  () => import('../pages/Management/Fines/Refer/Vehicle'),
);

const Charges = lazy(() => import('../pages/Management/Charges'));

const ConductorFineForm = lazy(
  () => import('../pages/Management/Fines/Refer/Condutctor'),
);

const CommuniqueList = lazy(() => import('../pages/Communique/List'));

const CommuniqueCreate = lazy(() => import('../pages/Communique/Create'));

export const AppRoutes = () => {
  usePageTracking();

  const { token, groups, currentGroupId, costCenters } = useAppSelector(
    state => state.authSlicer.user,
  );

  const [fetchCostCenters] = useLazyGetCostCenterQuery();
  const dispatch = useAppDispatch();

  const fetchCostCenter = useCallback(async () => {
    const groupsString = groups
      ?.map(item => `grupoId=${item.groupId}`)
      .join('&');

    try {
      if (groupsString) {
        const response = await fetchCostCenters(groupsString).unwrap();
        dispatch(setCostCenters(response));
      }
    } catch {
      //
    }
  }, [groups, currentGroupId]);

  useEffect(() => {
    if (token && !costCenters) fetchCostCenter();
  }, [token]);

  if (!token) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <Suspense
      fallback={
        <Center mt="20%">
          <Loading />
        </Center>
      }
    >
      <Routes>
        <Route path="/inicio" element={<Dashboard />} />

        <Route path="/" element={<Navigate to="/inicio" />} />
        <Route path="*" element={<Navigate to="/inicio" />} />

        <Route path="/relatorios">
          <Route index element={<Empty />} />
          <Route path="modelos" element={<Models />} />
          <Route path="disponibilidade" element={<Availability />} />
          <Route path="manutencao">
            <Route path="entradasaida" element={<EntranceExit />} />
            <Route path="servicos" element={<Repair />} />
            <Route path="preventivas" element={<Preventives />} />
          </Route>
          <Route path="distdefrotas" element={<FleetDist />} />
        </Route>

        <Route path="/agendamentos" element={<Schedules />} />

        <Route path="/novo-agendamento">
          <Route index element={<Empty />} />
          <Route path="veiculos/:plate?" element={<VehicleForm />} />
          <Route path="servicos" element={<ServicesForm />} />
          <Route path="agenda" element={<ScheduleForm />} />
          <Route path="lote" element={<MultipleForm />} />
          <Route path="revisao" element={<TableView />} />
          <Route path="imagens" element={<ImagesForm />} />
        </Route>

        <Route path="/centraldeduvidas">
          <Route index element={<FaQ />} />
          <Route path="lista/:name?/:id" element={<QuestionList />} />
          <Route path="pergunta/:name?/:id" element={<FaqQuestion />} />
          <Route path="pergunta/criar" element={<FormQuestion />} />
          <Route path="pergunta/editar/:id" element={<FormQuestion isEdit />} />
          <Route path="editarcategorias" element={<EditCategorie />} />
        </Route>

        <Route path="/faturamento">
          <Route index element={<FinancialList />} />
        </Route>

        <Route path="/administrativo">
          <Route index element={<Empty />} />
          <Route path="comunicados">
            <Route index element={<CommuniqueList />} />
            <Route path="comunicado/:id?" element={<CommuniqueCreate />} />
          </Route>

          <Route path="logs" element={<Logs />} />
          <Route path="logspdv" element={<LogsPdv />} />
        </Route>

        <Route path="/comunicados">
          <Route index element={<CommuniqueList />} />
          <Route path="comunicado/:id?" element={<CommuniqueCreate />} />
        </Route>

        <Route path="/gestao">
          <Route path="veiculos/:id?" element={<Vehicles />} />
          <Route path="recall" element={<Recall />} />

          {!isProd() && (
            <>
              <Route path="cobrancas" element={<Charges />} />
              <Route path="multas" element={<Fines />} />

              <Route path="multa">
                <Route path="veiculo" element={<FineVehicleForm />} />
                <Route path="condutor" element={<ConductorFineForm />} />
              </Route>
            </>
          )}
        </Route>

        {!isProd() && (
          <>
            <Route path="/acessos" element={<AccessList />} />
            <Route path="/acessos/usuario/:id?" element={<AccessUser />} />

            <Route path="/gestaodecontratos">
              <Route path="contrato/:id" element={<Contract />} />
              <Route index element={<ContractsList />} />
            </Route>
          </>
        )}
      </Routes>
    </Suspense>
  );
};
